import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resourcesToBackend from 'i18next-resources-to-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const LOCALIZATION_ON = false; //turn on when working on it... or once released and available,
//otherwise wil select the language, but be unchangeable

// bort.mop();
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
export function initTransitronics()
{
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector) // detector: in init optons to set options - see docs
    .use(resourcesToBackend(async (language:string, namespace:string) => {
      try {
        const v = await import(`../languages/${language}.json`);
        return v;
      } catch(e) {
        console.log('i18n: error loading language', e);
        alert('There was a problem switching languages. Please reload the page and try again.');
        throw e;
      }
    }))
    .init({
      // lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
      // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
      // if you're using a language detector, do not define the lng option
      supportedLngs: LOCALIZATION_ON ? ['en', 'fr', 'es'] : ['en'],
      // keySeparator: false, // may need this is keys ARE content... since content may contain a . !
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false // react already safes from xss
      },
      // debug: true,
    }, (err, t) => {
      if (err) {
        return console.log('i18n: something went wrong loading', err);
      }
      console.log(`i18 test key(Preferred Language):[${t('Preferred Language')}`); // -> same as i18next.t
    });    
}

export default i18n;