import React, { PropsWithChildren } from 'react'

import { VeriClockApiProvider as CommonApiProvider, VeriClockApiContextType } from './ApiProviderCommon'

export { useVeriClockApi } from './ApiProviderCommon';

export const VeriClockApiProvider:React.FC<PropsWithChildren<VeriClockApiContextType>> = ({children, ...restProps}) => {
    if(restProps.setAuthToken)
        throw new Error('WEB VeriClockApiProvider wasn\'t expecting a setAuthToken obj passed in...'); //discuss if we need this

    return <CommonApiProvider {...restProps}>
        {children}
    </CommonApiProvider>
}
