import React, { PropsWithChildren, useContext } from "react";
import { ApiConnectionSettings, VeriClockApi } from "../../../vericlock_api/src/client";
import { ApiGuid } from "../../../vericlock_api/src/types/ApiGuid";

export type ApiUploaderParams = {
    uri: string,    //ie '/1.0/kiosks/pictureSync'
    fields: Record<string,string>
    filename: string, //just name
    filepath: string, //(path+name)
    // filetype?: string, //mime type - RNFS will use from file extension if not passed in
    progress?: (progressUpdate: { 
        // jobId: number,
        bytesToSend: number,
        bytesSent: number,
    }) => void
}
export type ApiUploader = (params: ApiUploaderParams) => Promise<{
    //response from React Native FS file uploader
    jobId: number // The upload job ID, required if one wishes to cancel the upload. See `stopUpload`.
    statusCode: number // The HTTP status code
    headers: Record<string,string> // The HTTP response headers from the server
    body: string // The HTTP response body>
}>
export type VeriClockApiContextType = {
    api: VeriClockApi,
    makeApi: (params:{
        accountGuid: ApiGuid|null,
        userGuid:ApiGuid|null,
        authtoken:ApiGuid|null,
    }) => { api: VeriClockApi, apiUploader: ApiUploader },
    apiUploader: ApiUploader
    setAuthToken?:(authToken:string|null) => void,
    showUserError?:(msg:string) => void
    getDefaultApiConnectionSettings: () => ApiConnectionSettings
}
const VeriClockApiContext = React.createContext<VeriClockApiContextType|null>(null);

export const VeriClockApiProvider:React.FC<PropsWithChildren<VeriClockApiContextType>> = ({children, api, makeApi, apiUploader, setAuthToken, showUserError, getDefaultApiConnectionSettings}) => 
{
    const ctx:VeriClockApiContextType = React.useMemo(() => {
        return {
            api,
            makeApi,
            apiUploader,
            setAuthToken,
            showUserError,
            getDefaultApiConnectionSettings
        }
    },[api, apiUploader, setAuthToken, showUserError,getDefaultApiConnectionSettings,makeApi]);
    return <VeriClockApiContext.Provider value={ctx}>
        {children}
    </VeriClockApiContext.Provider>
}
export function useVeriClockApi()
{
    let ctx = useContext(VeriClockApiContext);
    if(!ctx)
        throw new Error('VeriClockApiProvider must be used higher in the component tree');

    //experimental - a way to use the regular hooks in the admin dashboard
    // by way of calling the query key maker and option getter in here,
    // an extra query key could be added per query, to isolate customer A from B
    //  would also inject headers into the api calls as needed    
    // const queryKeyMaker = useCallback((key:string|(string|number)[]) => {
    //     if(typeof(key) === 'string')
    //         return [key, ctx.queryId]
    //     else 
    //         return [...key, ctx.queryId];
    // },[ctx.queryId])
// 
    // const getSupplementalQueryOptions = useCallback( (key:VQueryKey) => {
    //     if(ctx.queryId) //in special mode, cache expires more easily
    //     {
    //         const opt:OurUseQueryOptions<any> = {
    //             staleTime: 1000, //never refetch except on invalidation
    //             refetchOnWindowFocus: false, //freshens data after leavin the window (less chance of stale data)
    //             refetchOnMount: true, //refetch if stale - see infinity staleTime
    //             refetchOnReconnect: false, //unsure
    //         }
    //         return opt;
    //     }
    //     return {};
    // },[ctx.queryId]);
    return ctx;
        // queryKeyMaker,
        // getSupplementalQueryOptions
    // }

}