//docs call to JUST import, and let side effects do the rest,
//but I suspect webpack is tree shaking it out of existence since nothing uses the exports (yet)
//and it is not configured to have a side effect so is ignored?  This pattern forces it
import React, { useCallback, useMemo } from 'react';
import {initTransitronics} from './i18n'; //early import of the localization engine
import { useTranslation as useInternalTranslation } from 'react-i18next';
// import { useCompanyLoaded } from '../hooks/companyHooks';
// import { usePersonalSettings } from '../hooks/PersonalSettingsHooks';
// import { useV } from '../hooks/V';

initTransitronics();

type TranslationProviderContextType = {
    changeLanguage:(lang:string|undefined)=>void
}
const TranslationProviderContext = React.createContext<TranslationProviderContextType|null>(null);

export function TranslationProvider(props:{
    children:React.ReactNode
})
{
    // const { company } = useCompanyLoaded();
    // const user = useV().getUser();
    // user.settings.language <-- //put here for access from core data... personal settings sets...and then cache updates ees? like visual settings?
    // const { personalSettings } = usePersonalSettings();

    const { i18n } = useInternalTranslation();

    const changeLanguage = useCallback((lang:string|undefined) => {
        console.log('TranslationProvider: changing language: ', lang);

        //use the browsers current locale (or back to english if lang isn't set)
        if(!lang)
        {
            //delete localstorage key for i18next: "i18nextLng"
            localStorage.removeItem('i18nextLng'); //should trigger redetection of the browser language
        }

        i18n.changeLanguage(lang ? lang : undefined);
    },[i18n]);

    const ctx = useMemo(() => {
        return {
            changeLanguage
        }
    },[changeLanguage]);

    return <TranslationProviderContext.Provider value={ctx}>
        {props.children}
    </TranslationProviderContext.Provider>
}

export function useOurTranslation()
{
    const ctx = React.useContext(TranslationProviderContext);
    if(ctx === null) {
        throw new Error('useTranslation must be invoked from within a TranslationProvider component');
    }
    const internalUseTranslation = useInternalTranslation();
    return {
        ...internalUseTranslation,
        ...ctx,
    }
}
