import { VeriClockApi, ReqInfo, ResponsePluginAction } from './client';

export const BrowserApiClient = new VeriClockApi({
    publicKey: "7585190d-cd6c-7f14-4710-f40fb0308d31",   //browser-based public key (it will fall back to cookie-auth if an authtoken is not present)
    responsePlugin: async (reqInfo: ReqInfo, res:Response, json: any) => {
        _signalVeriClockInfoHeader(res);
        const r:ResponsePluginAction = {
            action: 'continue'
        }
        return r;
    }
});

function _signalVeriClockInfoHeader(res: Response)
{
    try {
        let data = res.headers.get('vericlock_info');
        let showFlair = false;
        if(data)
        {
            let decodedObj = JSON.parse(atob(decodeURIComponent(data)));
            if(decodedObj)
            {
                if(decodedObj.n == 1)
                {
                    showFlair = true;
                }
            }
        }
        _showNewNotificationFlair(showFlair);

    } catch (err)
    {
        console.log("Error trying to check vericlock_info return object");
        console.log(err);
    }
}
function _showNewNotificationFlair(showFlair:boolean)
{
    let e = document.getElementsByClassName('newNotificationsFlair');
    for(let i=0; i < e.length; i++)
    {
        let elem = e[i] as HTMLElement;
        elem.style.display = showFlair ? "inline-block" : "none";
    }
}