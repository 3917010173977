import { useCallback } from "react";
import { useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { Company, CompanySettingsForUpdate, QueryCoreDataResponse } from "../../../vericlock_api/src/types/Company";
import { ReactQueryCommonUseQueryOptions, ReactQueryExtractErrorString, VQueryKey } from "../../../sharedReact/src/hooks/queryHookHelpers";

import moment from "moment-timezone";
import { useVeriClockApi } from "../../../sharedReact/src/hooks/ApiProvider";
import { VisualCustomizationsCombined } from "../../../vericlock_api/src/types";

const commonUseQueryOptionsCompany:UseQueryOptions<Company> = {
    ...ReactQueryCommonUseQueryOptions
}


//doesn't ACTUALLY load from server ,.. yet, comes from cache injected by PHP, but will load eventually - but potentially broken up into a few more logical units rather than a mega blob
//when we write an auth hook/route, it could receive the company object and populate the company cache directly, 
//until then, a refetch here is .. ok?
type CompanyHookProps = {
    enabled?:boolean
}
export function useCompany({
    enabled=true
}:CompanyHookProps={})
{
    const {api} = useVeriClockApi();
    const queryClient = useQueryClient();
    const query = useQuery<Company>(VQueryKey.Company, async () => (await api.getCompany()).payload , { 
        ...commonUseQueryOptionsCompany,
        enabled
    });

    
    const updateQueryCacheForCompany = useCallback((company:Company) => {

        let coreData = queryClient.getQueriesData<QueryCoreDataResponse>(VQueryKey.CoreData);
        coreData.forEach( cache => {
            let newVal = {
                ...cache[1],
                company
            }
            queryClient.setQueryData<QueryCoreDataResponse>(VQueryKey.CoreData, newVal);
        })
    },[queryClient]);

    const patchVisualCustomizations = useCallback( async (params: VisualCustomizationsCombined) => {
        if(params.company)
        {
            let coreData = queryClient.getQueriesData<QueryCoreDataResponse>(VQueryKey.CoreData);
            if(coreData.length > 0)
            {
                let company = coreData[0][1].company;
                let newCompany = {
                    ...company,
                    settings: {
                        ...company.settings,
                        visualCustomizations: params.company
                    }
                }
                updateQueryCacheForCompany(newCompany);
            }
        }
    },[queryClient, updateQueryCacheForCompany]);
    
    const updateSettings = useCallback( async (settings: CompanySettingsForUpdate) => {
        let newCompany = await api.updateCompanySettings(settings);
        queryClient.setQueryData<Company>(VQueryKey.Company, newCompany.payload);

        //update core data which contains a copy of the company
        updateQueryCacheForCompany(newCompany.payload);
    },[api, queryClient, updateQueryCacheForCompany]);



    const { errorString: loadError } = ReactQueryExtractErrorString(VQueryKey.Company, query);
    return {
        isLoading: query.isLoading,
        isFetched: query.isFetched,
        loadError,
        company: query.data,
        updateSettings,
        patchVisualCustomizations
    }    
}

//wraps the hook and returns a defined always value for company
export function useCompanyLoaded(props:CompanyHookProps={})
{
    const company = useCompany(props);
    if(!company.company)
        throw new Error('company undefined - useCompany must be invoked higher in the component chain for this function to work');
    
    return {
        ...company,
        company: company.company
    };
}

export function useCompanyDates()
{
    const company = useCompany();
    const platformTodayMoment = moment();
    const viewerTodayAsDayOfWeek = platformTodayMoment.format('ddd').toLowerCase();
    //use company tz if available, otherwise grab from the browser
    const todayAsDayOfWeek = company.company?.settings.timezone ? 
        moment().tz(company.company?.settings.timezone).format('ddd').toLowerCase() :
        viewerTodayAsDayOfWeek;


    return {
        today: todayAsDayOfWeek,
        platformToday: viewerTodayAsDayOfWeek,
        platformTodayMoment
    }
}