
//string like 1.1.0 (25) 
//last part is build number and not always there
//a regex to extract the semver build version components and possible build number. Friendly to possible white space at the front and between the patch number and build string
const versionExtractorRegex = /^\s*(\d+)\.(\d+)\.(\d+)\s*(?:\((\d+)\))?\s*$/;
//the ?: in the regex is 
export type SemverBuild = {
    major: number,
    minor: number,
    patch: number,
    build?: number
}
export function parseSemverBuildString(versionString:string)
{
    return extractVersionAndBuild(versionString);
}
function extractVersionAndBuild(versionString:string):SemverBuild
{
    const match = versionString.match(versionExtractorRegex);
    if(!match)
        throw new Error('Invalid version string');
    const major = parseInt(match[1]);
    const minor = parseInt(match[2]);
    const patch = parseInt(match[3]);
    const build = match[4] ? parseInt(match[4]) : undefined;
    return {
        major,
        minor,
        patch,
        build
    }
}
//compare versions, ignoring the build number
export function isVersionGreaterThanOrEqual(a:SemverBuild, b: SemverBuild)
{
    if(a.major > b.major)
        return true;
    if(a.major < b.major)
        return false;
    if(a.minor > b.minor)
        return true;
    if(a.minor < b.minor)
        return false;
    if(a.patch > b.patch)
        return true;
    if(a.patch < b.patch)
        return false;

    return true;
}
function isVeresionGreatThan(a:SemverBuild, b: SemverBuild)
{
    if(isVersionGreaterThanOrEqual(a,b))
    {
        //might be equal...if equal, return false
        if(a.major === b.major && a.minor === b.minor && a.patch === b.patch)
            return false;
        return true; //not equal case, so greater than
    }

    //fall through to false
    return false;
}


//maps a string feature key, to an ios/android => version (build) string
type StringVersionMap = { min?: string, max?: string, all?: boolean };
type FeatureVersionMap<T extends string> = Record<T, {
    android: StringVersionMap
    ios: StringVersionMap
}>
type SemverVersionMap = { min?: SemverBuild, max?: SemverBuild, all?: boolean };
type FeatureSemVerMap<T extends string> = Record<T, {
    android: SemverVersionMap
    ios: SemverVersionMap
}>
export class FeatureVersionMapper<T extends string>
{
    private versionForFeature:FeatureSemVerMap<T>
    constructor(versionForFeature:FeatureVersionMap<T>)
    {
        //parse STRING version into SEMVER version
        this.versionForFeature =  Object.keys(versionForFeature).reduce((acc, featureKey) => {
            const versionMap = versionForFeature[featureKey as T];
            
            acc[featureKey as T] = {
                android: this.makeSemverMap(versionMap.android),
                ios: this.makeSemverMap(versionMap.ios)
            }
            return acc;
        }, {} as FeatureSemVerMap<T>);        
    }
    private makeSemverMap(versionString:StringVersionMap):SemverVersionMap
    {
        let map:SemverVersionMap = {
            all: versionString.all,
        }
        if(versionString.min)
            map.min =  extractVersionAndBuild(versionString.min);
        if(versionString.max)
        {
            map.max =  extractVersionAndBuild(versionString.max);
            throw new Error('MAX version values not supported yet!'); //need to finish here and in the version checker below
        }
        return map;
    }

    doesVersionSupportFeature(platform: 'ios'|'android', version:SemverBuild|string|null, feature:T):boolean
    {
        const versionInfo = this.versionForFeature[feature][platform] as SemverVersionMap;

        if(versionInfo.all)    
            return true; //all versions supported - regardless of version of system

        if(version === null)
        {
            return false;
        }
        
        const semverBuild = typeof(version) === 'string' ?  extractVersionAndBuild(version) : version; //parse into SemverBuild if needed
        if(!versionInfo.min)
            throw new Error(`No min versionInfo.min for feature ${feature} defined`); //basically a typeguard, but alternative sanity checker to the tsc 
        if(isVersionGreaterThanOrEqual(semverBuild, versionInfo.min))
        {
            //meets min version
            //todo: check if it exceeds max version
            if(versionInfo.max)
            {
                //is the app version greater than the last supported version?
                if(isVeresionGreatThan(versionInfo.max, semverBuild))
                {
                    return false; //it is now dead - not supported
                }
            }
            //no max or within max
            return true;
        }
        //app doesn't meet min version, not supported
        return false;
    }
}