import React, { PropsWithChildren, useMemo, useState } from 'react';
import { parse as parseQueryString } from 'query-string';
import { useCoreData } from '../hooks/CoreDataHooks';

import { VLoadingIndicator } from '../ui/components/core/VLoadingIndicator';
import { parseSemverBuildString, SemverBuild } from "../../../lib/src/helpers/semverHelper"
import { useLocation } from 'react-router-dom';
import { QueryCoreDataResponse } from '../../../vericlock_api/src/types';

export type AppContextType = AppContextTypeAuthenticated | AppContextTypeUnauthenticated;

type AppContextTypeAuthenticated = {
    authenticated: true
    isEmbeddedView: boolean
    embeddedAppVersion: SemverBuild|null
    embeddedAppPlatform: 'ios'|'android'|null
    coreData: QueryCoreDataResponse
}
type AppContextTypeUnauthenticated = {
    authenticated: false
    isEmbeddedView: boolean
    embeddedAppVersion: SemverBuild|null
    embeddedAppPlatform: 'ios'|'android'|null
    coreData: undefined
}
const AppContext = React.createContext<AppContextType|null>(null);

export function useAppContext(authenticated?:true):AppContextTypeAuthenticated;
export function useAppContext(authenticated?:false):AppContextTypeUnauthenticated;
export function useAppContext(authenticated?:boolean):AppContextType
{
    const context = React.useContext(AppContext);
    if(!context)
        throw new Error('useAppContext found null - missing using inside <AppContext.Provider>'); //shouldn't happen outside initial dev
    return context;    
}

const unauthenticatedPaths = [
    "/v",
    "/login",
    /^\/intuit\//,
    /^\/createAccount/,
    /^\/email\/manage/,
];
function isPathUnauthenticated(path:string)
{
    return unauthenticatedPaths.some(p => {
        if(typeof(p) === 'string')
            return path === p;
        return p.test(path);
    });
}

export const AppContextProvider:React.FC<PropsWithChildren> = ({children}) => {
    const location = useLocation();

    let isUnauthenticatedPath = isPathUnauthenticated(location.pathname);
    const coreData = useCoreData({
        enabled: !isUnauthenticatedPath, //only load core data if the path is not an unauthenticated one
    });
    //this is run once on mount - because the url can change during embedded use and the query param is not maintained
    //the embedded view stays the same, however
    
    //useState -> so it is done ONCE on mount - the query params will be lost after that as things route and change 
    //      the url
    const [{ isEmbeddedView, embeddedAppVersion, embeddedAppPlatform }] = useState(() => {

        if(location.search.search(/embedded=true/)!=-1
            || location.pathname.endsWith('/embedded'))
        {
            const parsed = parseQueryString(location.search);
            const embeddedAppVersionsString = parsed.embeddedAppVersion;
            let embeddedAppPlatformString = typeof(parsed.embeddedAppPlatform) === 'string' ? parsed.embeddedAppPlatform : null;
            let embeddedAppPlatform:'ios'|'android'|null = embeddedAppPlatformString === 'ios' ? 'ios' : embeddedAppPlatformString==='android' ? 'android' : null;
            
            let embeddedAppVersion:SemverBuild|null = null;
            
            if(typeof(embeddedAppVersionsString) === 'string')
            {
                try {
                    embeddedAppVersion = parseSemverBuildString(embeddedAppVersionsString);

                }
                catch(err)
                {
                    console.warn('could not parse embeddedAppVersion', err,embeddedAppVersionsString);
                }
            }
            return { isEmbeddedView: true, embeddedAppVersion, embeddedAppPlatform }
        }
        return {isEmbeddedView: false, embeddedAppVersion: null, embeddedAppPlatform:null };
    });

    
    //unauthenticated route, coreData is undefined, but other info present
    const ctx:AppContextType =  useMemo(() => {
        if(!isUnauthenticatedPath && coreData.coreData)
        {
            return {
                authenticated: true,
                isEmbeddedView,
                embeddedAppVersion,
                embeddedAppPlatform,
                coreData: coreData.coreData
            };
        }
        else 
        {
            return {
                authenticated: false,
                isEmbeddedView,
                embeddedAppVersion,
                embeddedAppPlatform,
                coreData: undefined
            };
        }
    }, [isUnauthenticatedPath, isEmbeddedView, embeddedAppVersion, embeddedAppPlatform, coreData.coreData]);

    if(!isUnauthenticatedPath && coreData.isLoading)
        return <VLoadingIndicator slowLoadingThresholdMs={5000}/>
    if(!isUnauthenticatedPath && (coreData.loadError || !coreData.coreData))
        return <div className="alert alert-danger">Cannot load site - please reload the page and try again</div>

    return <AppContext.Provider value={ctx}>
        {children}
    </AppContext.Provider>
}