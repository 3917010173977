import { useQuery, UseQueryOptions, useQueryClient } from "react-query";
import { QueryCoreDataResponse } from "../../../vericlock_api/src/types";
import { ReactQueryCommonUseQueryOptions, ReactQueryExtractErrorString, VQueryKey } from "../../../sharedReact/src/hooks/queryHookHelpers";
import { useVeriClockApi } from "../../../sharedReact/src/hooks/ApiProvider";
import { useOurTranslation } from "../app/TranslationProvider";
import { useEffect } from "react";


const commonUseQueryOptionsCompany:UseQueryOptions<QueryCoreDataResponse> = {
    ...ReactQueryCommonUseQueryOptions,
    staleTime: 5000, //really no easy way to keep this cached and update it correctly
}

// enum AuthState {
//     Authenticated = 'authenticated',
//     NotAuthenticated = 'notAuthenticated',
//     Unauthorized = 'unauthorized' //specifically blocked?
//     AccountLocked = 'accountLocked',

// }

export function useCoreData(opts?: {
    enabled?:boolean
})
{
    const { enabled=true } = opts ?? {};
    // const [authState, setAuthState] = useState()
    const { api } = useVeriClockApi();
    const queryClient = useQueryClient();
    const { changeLanguage } = useOurTranslation();

    //patched elsewhere in code - if this query key changes, search and update other patching instances to comply
    const query = useQuery<QueryCoreDataResponse>([VQueryKey.CoreData], async () => {
        try { 
            let res = await api.getCoreData();
            queryClient.setQueryData(VQueryKey.Company, res.payload.company);
            return res.payload;
        }
        catch(err)
        {            
            //handle de-authentication here
            //5xx errors/timeouts?
            console.warn('coreData fetch error', err);
            throw err;
        }    
    }, { 
        ...commonUseQueryOptionsCompany,
        enabled
    });

    useEffect(() => {
        if(!query.isLoading)
        {
            if(query.data?.personalSettings.language)
            {
                console.log('CoreData - lang change: changing language: ', query.data?.personalSettings.language);
                changeLanguage(query.data?.personalSettings.language);
            }
        }
    },[query.isLoading, changeLanguage, query.data?.personalSettings.language]);
    //if we want the render tree to persist after a de-auth, so we can re-auth, we should maintain a copy of the data in the state tree,
    //so we should not ship out data that can change
    //does query.data stay stable if it later 40xs on a refetch? probably..        
    const { errorString: loadError } = ReactQueryExtractErrorString(VQueryKey.AuditTrail, query);
    return {
        isLoading: query.isLoading,
        isFetched: query.isFetched,
        loadError,
        coreData: query.data,
    }    
}

export function useCoreDataLoaded()
{
    const { coreData } = useCoreData();
    if(!coreData)
        throw new Error('coreData is not loaded - missing context provider')
    return coreData;
}